









































































































































































































import { Component, Vue } from "vue-property-decorator";
import { DxDateBox } from "devextreme-vue";
import "vuex";
import "vuetify";
import entrevistaModule from "@/store/modules/entrevista-module";
import PersonalizacionesModule from "@/store/modules/personalizaciones/client371/Personalizaciones-module";
import { Usuarioentrevista371 } from "@/shared/dtos/personalizaciones/client371/Usuarioentrevista371";
import { UtilsString } from "@/utils/utils-string";
import { gustos_platosDto } from "@/shared/dtos/visualizacion_dieta/entrevista/gustos_platosDto";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";

@Component({
  components: {
    DxDateBox,
    TableSelect: () => import("@/components/DataTableSelect/TableSelect.vue"),
  },
})
export default class AsistenteCliente371Preguntas extends Vue {
  public onboarding: number = 0;
  public datos: Record<string, any> = {};
  public preparado = false;
  public objetivo_principal: { text: string; value: number }[] = [];
  public estilos_de_alimentacion: {
    text: string;
    value: number;
    descripcion: string;
  }[] = [];
  public actividades_fisicas: {
    text: string;
    value: number;
    descripcion: string;
  }[] = [];
  public cliente_existente = false;
  public id_cliente = 0;
  public alimentos_nogustan: gustos_platosDto[] = [];
  public opciones_alimentos: gustos_platosDto[] = [];

  async created() {
    //await entrevistaModule.getnuevaentrevista(1);
    this.id_cliente = parseInt(this.$route.params.id);
    console.log("cliente", this.id_cliente);
    await PersonalizacionesModule.getpersonalizacion(this.id_cliente);
    this.CrearEstilosDeAlimentacion();
    this.CrearObjetivoPrincipal();
    this.CrearActividadesFisicas();

    /*  this.datos["fecha_nacimiento"] =PersonalizacionesModule.personalizacion_entrevista.fechaNacimiento;*/

    if (
      !UtilsString.IsNullOrWhiteSpace(
        PersonalizacionesModule.personalizacion_entrevista.nombre
      )
    ) {
      this.datos["fecha_nacimiento"] =
        PersonalizacionesModule.personalizacion_entrevista.fechaNacimiento;
      this.datos["nombre"] =
        PersonalizacionesModule.personalizacion_entrevista.nombre;
      this.datos["apellidos"] =
        PersonalizacionesModule.personalizacion_entrevista.apellidos;
      this.datos["email"] =
        PersonalizacionesModule.personalizacion_entrevista.email;
      this.datos["genero"] =
        PersonalizacionesModule.personalizacion_entrevista.genero;
      this.datos["altura"] =
        PersonalizacionesModule.personalizacion_entrevista.altura;
      this.datos["peso"] =
        PersonalizacionesModule.personalizacion_entrevista.peso;

      this.datos["muñeca"] =
        PersonalizacionesModule.personalizacion_entrevista.muneca;
      this.datos["cintura"] =
        PersonalizacionesModule.personalizacion_entrevista.cintura;
      this.datos["cadera"] =
        PersonalizacionesModule.personalizacion_entrevista.cadera;
      this.$set(
        this.datos,
        "intoleracia_alimentaria",
        PersonalizacionesModule.personalizacion_entrevista.intolerancias
      );
      try {
        this.preguntas.find(
          (pregunta) => pregunta.campo === "intoleracia_alimentaria"
        )!.visible = false;
      } catch (error) {}

      this.onboarding = 6;
      this.cliente_existente = true;
    }

    var gustos_filter_no_gustan =
      PersonalizacionesModule.personalizacion_entrevista.gustos.filter(
        (x: gustos_platosDto) => x.seleccionado === true
      );

    var todos = PersonalizacionesModule.personalizacion_entrevista.gustos.map(
      (x) => new gustos_platosDto(x)
    );

    this.$set(this.datos, "gustos", gustos_filter_no_gustan || []);
    this.opciones_alimentos.push(...todos);

    /*this.datos["actividad_fisica"] =
      PersonalizacionesModule.personalizacion_entrevista.actividad_fisica;*/

    // Inicializamos el campo como un array vacío si aún no lo es
    if (!Array.isArray(this.datos["intoleracia_alimentaria"])) {
      this.$set(this.datos, "intoleracia_alimentaria", []);
    }

    /*if (!Array.isArray(this.datos["gustos"])) {
      await alimentoModule.getall_alimentos_gustos_paciente(0);
      this.$set(this.datos, "gustos", []);
    }*/

    this.preparado = true;
  }

  public get colorFondo() {
    try {
      return this.$route.query.color
        ? `#${this.$route.query.color}`
        : "#FFFFFF";
    } catch (error) {
      return "#FFFFFF";
    }
  }

  opcionSeleccionada(pregunta: any) {
    const opciones = pregunta.opciones || [];
    var seleccionado = opciones.find(
      (opcion: any) => opcion.value === this.datos[pregunta.campo]
    );
    if (!seleccionado) {
      return "";
    }
    return UtilsString.IfUndefinedPutThis(seleccionado.descripcion, "");
  }

  public CrearActividadesFisicas() {
    for (
      let i = 0;
      i <
      PersonalizacionesModule.personalizacion_entrevista
        .opcionesActividadesFisicas.length;
      i++
    ) {
      const element =
        PersonalizacionesModule.personalizacion_entrevista
          .opcionesActividadesFisicas[i];
      this.actividades_fisicas.push({
        text: element.nombre,
        value: element.id,
        descripcion: "",
      });
    }
  }
  public CrearEstilosDeAlimentacion() {
    for (
      let i = 0;
      i <
      PersonalizacionesModule.personalizacion_entrevista
        .opcionesPatronAlimenticio.length;
      i++
    ) {
      const element =
        PersonalizacionesModule.personalizacion_entrevista
          .opcionesPatronAlimenticio[i];
      this.estilos_de_alimentacion.push({
        text: element.nombre_patologia,
        value: element.id,
        descripcion: element.descripcion_patologia,
      });
    }
  }

  public CrearObjetivoPrincipal() {
    for (
      let i = 0;
      i <
      PersonalizacionesModule.personalizacion_entrevista
        .opcionesObjetivoPrincipal.length;
      i++
    ) {
      const element =
        PersonalizacionesModule.personalizacion_entrevista
          .opcionesObjetivoPrincipal[i];
      this.objetivo_principal.push({
        text: element.nombre_patologia,
        value: element.id,
      });
    }
  }

  public get DataEntrevista() {
    return entrevistaModule.entrevista;
  }

  public todas_preguntas = [
    { campo: "nombre", label: "Nombre", tipo: "text", visible: true },
    { campo: "apellidos", label: "Apellidos", tipo: "text", visible: true },
    { campo: "email", label: "Email", tipo: "text", visible: true },
    {
      campo: "fecha_nacimiento",
      label: "Fecha de nacimiento",
      tipo: "date",
      visible: true,
    },
    {
      campo: "genero",
      label: "Género",
      tipo: "v-select",
      opciones: [
        { text: "Masculino", value: "Masculino" },
        { text: "Femenino", value: "Femenino" },
      ],
      visible: true,
    },
    { campo: "altura", label: "Altura (cm)", tipo: "number", visible: true },
    { campo: "peso", label: "Peso (kg)", tipo: "number", visible: true },
    {
      campo: "actividad_fisica",
      label: "¿Cómo definirías tu actividad física?",
      tipo: "v-select",
      opciones: this.actividades_fisicas,
      visible: true,
    },
    {
      campo: "frecuencia_actividad_fisica",
      label: "¿Con qué frecuencia haces ejercicio a la semana?",
      tipo: "v-select",
      opciones: [
        { text: "0", value: "0" },
        { text: "1-2 veces", value: "1-2 veces" },
        { text: "3-4 veces", value: "3-4 veces" },
        { text: "5 o más veces", value: "5 o más veces" },
      ],
      visible: true,
    },
    {
      campo: "objetivo_principal",
      label: "¿Cuál es tu objetivo principal?",
      tipo: "v-select",
      opciones: this.objetivo_principal,
      visible: true,
    },
    {
      campo: "objetivo_secundario",
      label: "¿Tienes algún objetivo secundario?",
      tipo: "v-select",
      opciones: [
        { text: "Aumentar energía", value: "Aumentar energía" },
        { text: "Mejorar digestión", value: "Mejorar digestión" },
        { text: "Reducir estrés", value: "Reducir estrés" },
        {
          text: "Mejorar rendimiento deportivo",
          value: "Mejorar rendimiento deportivo",
        },
        { text: "Ninguno", value: "Ninguno" },
      ],
      visible: true,
    },
    {
      campo: "patron_alimenticio",
      label: "¿Cómo describirías tu patrón alimenticio actual?",
      tipo: "v-select",
      opciones: this.estilos_de_alimentacion,
      visible: true,
    },
    {
      campo: "intoleracia_alimentaria",
      label: "¿Tienes alguna alergia alimentaria o intolerancia?",
      tipo: "v-checkbox-group",
      opciones: [
        { text: "Lacteos", value: "Lacteos" },
        { text: "Huevos", value: "Huevos" },
        { text: "Soja", value: "Soja" },
        { text: "Apio", value: "Apio" },
        { text: "Altramuz", value: "Altramuz" },
        { text: "Pescado", value: "Pescado" },
        { text: "Sulfuro", value: "Sulfuro" },
        { text: "Nueces", value: "Nueces" },
        { text: "Mostaza", value: "Mostaza" },
        { text: "Moluscos", value: "Moluscos" },
        { text: "Sesamo", value: "Sesamo" },
        { text: "Cacahuetes", value: "Cacahuetes" },
        { text: "Gluten", value: "Gluten" },
      ],
      // opciones: this.estilos_de_alimentacion,
      visible: true,
    },
    {
      campo: "gustos",
      label: "¿Hay algún alimento que prefieras evitar?",
      tipo: "v-table-select",
      opciones: this.alimentos,
      headers: this.headers_alimentos,
      height: this.tolheight,
      visible: true,
    },
    {
      campo: "preferencias_alimentos",
      label: "¿Qué alimentos sanos prefieres incluir en tu dieta?",
      tipo: "v-table-select",
      opciones: this.alimentos,
      headers: this.headers_alimentos,
      height: this.tolheight,
      visible: false,
    },

    {
      campo: "comidas_diarias",
      label: "¿Cuántas comidas principales haces al día?",
      tipo: "v-select",
      opciones: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "Más", value: "Más" },
      ],
      visible: true,
    },
    {
      campo: "tiempo_comidas",
      label: "¿Tienes algún tiempo específico para preparar las comidas?",
      tipo: "v-radio-group",
      opciones: ["Menos de 15 minutos", "15-30 minutos", "Más de 30 minutos"],
      visible: true,
    },
    {
      campo: "meriendas",
      label: "¿Sueles comer bocadillos/merendar entre comidas?",
      tipo: "v-radio-group",
      opciones: ["Sí", "No"],
      visible: true,
    },
    {
      campo: "finalizar",
      label: "Confirmar datos y generar dieta",
      tipo: "v-btn",
      descripcion:
        "Basándose en tus respuestas, crearemos un menú personalizado que busca ser equilibrado y adaptado a tus objetivos y restricciones. Sin embargo, este menú no reemplaza en ningún caso un plan dietético elaborado por un nutricionista en una consulta profesional.",
      metodo: this.finalizar,
      visible: true,
    },
    {
      campo: "porcen_grasa",
      label: "porcentaje de grasa corporal",
      tipo: "number",
      visible: false,
    },
    {
      campo: "muñeca",
      label: "perímetro de muñeca",
      tipo: "number",
      visible: false,
    },
    {
      campo: "cintura",
      label: "perímetro de cintura",
      tipo: "number",
      visible: false,
    },
    {
      campo: "cadera",
      label: "perímetro de cadera",
      tipo: "number",
      visible: false,
    },
  ];

  public get preguntas() {
    return this.todas_preguntas.filter((pregunta) => pregunta.visible);
  }

  public finalizar() {
    var usuario = this.calcularDatos();

    /*var usuario = new Usuarioentrevista371({
          nombre: "Eric",
          apellidos: "Lozano",
          email: "ericlozano4@gmail.com",
          fechaNacimiento: "1998-02-12",
          genero: "Masculino",
          altura: 171,
          peso: 84,
          edad: 27,
          objetivoPrincipal: 134,
          objetivoSecundario: "Reducir estrés",--
          patronAlimenticio: 125,
          comidasDiarias: "4",--
          meriendas: "No",--
          actividad_fisica: 3,
          met: 1.2,
          peso_auto_calculado: 100.8,
          altura_auto_calculado: 172.71,
          porcen_grasa: 30,
          muneca: 21,
          cintura: 84.94,
          cadera: 95.98,
          Calorias_dieta: 2273,
        });*/

    //Gustos
    // Intolerancias intoleracia_alimentaria
    //frecuencia_actividad_fisica
    //tiempo_comidas

    console.log(usuario, this.id_cliente);
    usuario.id_paciente_cliente = this.id_cliente;

    usuario.actividad_fisica = this.actividades_fisicas.find(
      (actividad) => actividad.value === parseInt(usuario.actividad_fisica)
    )!.text;
    usuario.patronAlimenticio = this.estilos_de_alimentacion.find(
      (estilo) => estilo.value === parseInt(usuario.patronAlimenticio)
    )!.text;
    usuario.objetivoPrincipal = this.objetivo_principal.find(
      (objetivo) => objetivo.value === parseInt(usuario.objetivoPrincipal)
    )!.text;
    usuario.intolerancias = this.datos.intoleracia_alimentaria;
    usuario.frecuencia_actividad_fisica =
      this.datos.frecuencia_actividad_fisica;
    usuario.tiempo_comidas = this.datos.tiempo_comidas;

    console.log(usuario);
    PersonalizacionesModule.crear_dieta_personalizada(usuario).then((x) => {
      // Codificar los parámetros para evitar problemas con caracteres especiales
      console.log(x);
      //http://localhost:8081/pacientes/#/login
      //https://pacientes.mymedicaldiet.es/#/login?
      const url = `https://pacientes.mymedicaldiet.es/#/login/?usuario=${encodeURIComponent(
        x.acceso_app_usuario
      )}&password=${encodeURIComponent(x.acceso_app_pwd)}`;
      // Abrir la URL en una nueva pestaña
      window.open(url, "_blank");
    });
  }

  public next() {
    // Obtener la pregunta actual
    const preguntaActual = this.preguntas[this.onboarding];

    // Validar que el campo no esté vacío
    if (!this.validarCampo(preguntaActual)) {
      // Si el campo está vacío, no avanzar
      alert("Por favor, completa el campo: " + preguntaActual.label);
      return;
    }

    // Si el campo es válido, avanzar a la siguiente pregunta

    this.onboarding++;
  }
  public prev() {
    this.onboarding--;
  }

  validarCampo(pregunta: any) {
    // Verificar si el tipo de campo es uno que no debe estar vacío
    const valor = this.datos[pregunta.campo];

    // Si el valor está vacío, devolver falso
    if (pregunta.tipo !== "v-radio-group" && !valor) {
      return false;
    }

    // Para los radio buttons, comprobar si se seleccionó alguna opción
    if (pregunta.tipo === "v-radio-group" && !valor) {
      return false;
    }

    return true;
  }

  public get alimentos() {
    return this.opciones_alimentos;
  }

  public get headers_alimentos() {
    return [
      {
        text: "Nombre",
        value: "nombre",
        width: "50%",
      },
      { text: "Grupo", value: "grupo", width: "50%" },
    ];
  }

  public get tolheight() {
    return this.$vuetify.breakpoint.height - 260;
  }

  calcularDatos(): Usuarioentrevista371 {
    const usuario = new Usuarioentrevista371();
    const datos = this.datos;
    usuario.nombre = datos.nombre;
    usuario.apellidos = datos.apellidos;
    usuario.email = datos.email;
    usuario.fechaNacimiento = datos.fecha_nacimiento;
    usuario.genero = datos.genero;
    usuario.altura = parseInt(parseInt(datos.altura).toFixed(2));
    usuario.peso = parseInt(parseInt(datos.peso).toFixed(2));
    usuario.edad =
      new Date().getFullYear() - new Date(datos.fecha_nacimiento).getFullYear();
    usuario.objetivoPrincipal = datos.objetivo_principal;
    usuario.objetivoSecundario = datos.objetivo_secundario;
    usuario.patronAlimenticio = datos.patron_alimenticio;
    usuario.comidasDiarias = datos.comidas_diarias;
    usuario.meriendas = datos.meriendas;
    usuario.actividad_fisica = datos.actividad_fisica;

    usuario.met = this.calcularMET(datos.actividad_fisica);
    //usuario
    usuario.peso_auto_calculado = this.calcularPesoConActividad(
      usuario.peso,
      usuario.met
    );
    usuario.altura_auto_calculado = this.calcularAlturaConActividad(
      usuario.altura,
      usuario.met
    );

    usuario.porcen_grasa = this.calcularPorcentajeGrasa(usuario);
    usuario.muneca = this.calcularPerimetroMuneca(usuario);
    usuario.cintura = this.calcularPerimetroCintura(usuario);
    usuario.cadera = this.calcularPerimetroCadera(usuario);
    usuario.Calorias_dieta = parseInt(this.CaloriasMifflinStJeor(usuario).get);

    return usuario;
  }
  CaloriasMifflinStJeor(datos: Usuarioentrevista371) {
    // Calcular TMB según el género
    let tmb;
    if (datos.genero === "Masculino") {
      tmb = 10 * datos.peso + 6.25 * datos.altura - 5 * datos.edad + 5;
    } else {
      tmb = 10 * datos.peso + 6.25 * datos.altura - 5 * datos.edad - 161;
    }
    const get = tmb * datos.met;

    return {
      tmb: tmb.toFixed(2),
      get: get.toFixed(2),
    };
  }

  calcularCaloriasHarrisBenedict(datos: Usuarioentrevista371) {
    // Calcular TMB según el género
    let tmb;
    if (datos.genero === "Masculino") {
      tmb =
        66.5 + 13.75 * datos.peso + 5.003 * datos.altura - 6.75 * datos.edad;
    } else {
      tmb =
        655.1 + 9.563 * datos.peso + 1.85 * datos.altura - 4.676 * datos.edad;
    }
    const get = tmb * datos.met;

    return {
      tmb: tmb.toFixed(2),
      get: get.toFixed(2),
    };
  }

  calcularMET(
    actividad: "Muy ligera" | "Ligera" | "Moderada" | "Intensa" | "Muy intensa"
  ): number {
    const metValores = {
      "Muy ligera": 1.2,
      Ligera: 1.375,
      Moderada: 1.55,
      Intensa: 1.725,
      "Muy intensa": 1.9,
    };
    return metValores[actividad] || 1.2;
  }
  calcularPesoConActividad(peso: number, met: number): number {
    return peso * met;
  }

  calcularAlturaConActividad(altura: number, met: number): number {
    return altura * (1 + (met - 1) * 0.05);
  }
  // Porcentaje de grasa corporal con ajuste por actividad física
  calcularPorcentajeGrasa(usuario: Usuarioentrevista371): number {
    let porcentajeBase = 0;
    if (usuario.genero === "Masculino") {
      porcentajeBase =
        1.2 *
          (usuario.peso_auto_calculado /
            (usuario.altura_auto_calculado / 100) ** 2) +
        0.23 * usuario.edad -
        16.2;
    } else {
      porcentajeBase =
        1.2 *
          (usuario.peso_auto_calculado /
            (usuario.altura_auto_calculado / 100) ** 2) +
        0.23 * usuario.edad -
        5.4;
    }
    return parseInt(porcentajeBase.toFixed(0));
  }

  // Cálculo perímetros con ajustes por actividad física
  calcularPerimetroMuneca(usuario: Usuarioentrevista371): number {
    return parseFloat(
      (
        usuario.altura_auto_calculado / 10 +
        usuario.peso_auto_calculado / 30
      ).toFixed(2)
    );
  }

  calcularPerimetroCintura(usuario: Usuarioentrevista371): number {
    return parseFloat(
      (
        usuario.peso_auto_calculado / 2 +
        usuario.altura_auto_calculado / 5
      ).toFixed(2)
    );
  }

  calcularPerimetroCadera(usuario: Usuarioentrevista371): number {
    return parseFloat(
      (
        usuario.peso_auto_calculado / 1.5 +
        usuario.altura_auto_calculado / 6
      ).toFixed(2)
    );
  }
}
