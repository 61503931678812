import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { Usuarioentrevista371 } from '@/shared/dtos/personalizaciones/client371/Usuarioentrevista371';
import { patologia } from '@/shared/dtos/patologia';
import { gustos_platosDto } from '@/shared/dtos/visualizacion_dieta/entrevista/gustos_platosDto';

@Module({
    namespaced: true,
    name: 'PersonalizacionesModule',
    store,
    dynamic: true
})
class PersonalizacionesModule extends VuexModule {
    public personalizacion_entrevista: Usuarioentrevista371 = new Usuarioentrevista371();


    @Action({ commit: 'onGetpersonalizacion' })
    public async getpersonalizacion(id_cliente: number) {
        return await ssmHttpService.get(API.Personalizaciones + '/paciente/' + id_cliente);
    }


    @Action
    public async crear_dieta_personalizada(dieta_personalizada: Usuarioentrevista371) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
       return await ssmHttpService.post(API.Personalizaciones, dieta_personalizada.toJson());
    }


    @Mutation
    public onGetpersonalizacion(res: Usuarioentrevista371) {
        this.personalizacion_entrevista = new Usuarioentrevista371(res);
        this.personalizacion_entrevista.opcionesObjetivoPrincipal = res.opcionesObjetivoPrincipal ? res.opcionesObjetivoPrincipal.map((x) => new patologia(x)) : [];
        this.personalizacion_entrevista.opcionesPatronAlimenticio = res.opcionesPatronAlimenticio ? res.opcionesPatronAlimenticio.map((x) => new patologia(x)) : [];
        this.personalizacion_entrevista.gustos = res.gustos ? res.gustos.map((x) => new gustos_platosDto(x)) : [];
        console.log(res, this.personalizacion_entrevista);
    }


}
export default getModule(PersonalizacionesModule);