import { ActividadFisica } from "../../ActividadFisica";
import { BaseDto } from "../../base-dto";
import { patologia } from "../../patologia";
import { gustos_platosDto } from "../../visualizacion_dieta/entrevista/gustos_platosDto";

export class Usuarioentrevista371 extends BaseDto {
    public id_ultima_entrevista!: number;
    public id_paciente_my_medical!: number;
    public id_paciente_cliente!: number;
    public nombre!: string;
    public apellidos!: string;
    public email!: string;
    public fechaNacimiento!: string;
    public genero!: string;
    public altura!: number;
    public peso!: number;
    public edad!: number;
    public objetivoPrincipal!: string;
    public objetivoSecundario!: string;
    public patronAlimenticio!: string;
    public comidasDiarias!: string;
    public meriendas!: string;
    public actividad_fisica!: string;
    public peso_auto_calculado!: number;
    public altura_auto_calculado!: number;
    public porcen_grasa!: number;
    public muneca!: number;
    public cintura!: number;
    public cadera!: number;
    public Calorias_dieta!: number;
    public met!: number;
    public gustos!: gustos_platosDto[];
    public intolerancias!: [];
    public frecuencia_actividad_fisica!: string;
    public tiempo_comidas!: string;

    public opcionesObjetivoPrincipal!: patologia[];
    public opcionesPatronAlimenticio!: patologia[];
    public opcionesActividadesFisicas!: ActividadFisica[];
}